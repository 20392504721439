import React, { useEffect, useState, useRef } from 'react';
import { useNavigate, useLocation } from 'react-router';
import videojs from 'video.js';
import 'videojs-mobile-ui';
import 'video.js/dist/video-js.css';
import "videojs-markers";
import 'videojs-mobile-ui/dist/videojs-mobile-ui.css';
import 'videojs-hls-quality-selector';
import ZoomMtgEmbedded from '@zoom/meetingsdk/embedded'
import debounce from 'lodash.debounce';
import { createPopper } from "@popperjs/core";
import maxSize from "popper-max-size-modifier";

import './zoom.css'
import { ADMIN_USER_TYPE, STUDENT_USER_TYPE } from '../../services/UserService';
import SlotLiveDoubts from '../Slots/SlotLiveDoubts';
import { securedFetchSlotStatus, securedFetchTimetableSlotById } from '../../services/TimetableService';
import { navigateCallbackOptions } from '../../services/AuthenticationService';
import { MdOutlineFullscreen } from "react-icons/md";
import SlotLiveDoubtClusters from '../Slots/SlotLiveDoubtClusters';
import { LIVE_STREAM_ALIAS_URL } from '../../services/BackendConfig';
import Spinner from '../Common/Tailwind/Spinner';

const LiveStreamIframeView = ({ slotId, user = ADMIN_USER_TYPE }) => {

    const [lectureData, setLectureData] = useState(null);
    const [isFullscreen, setIsFullscreen] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();

    const closeTab = () => {
        window.opener = null;
        window.open("", "_self");
        window.close();
    };

    const getSrcUrl = () => {
        return `${LIVE_STREAM_ALIAS_URL}${lectureData.lecture_hall.live_stream_alias}&disableautofullscreen=1&disableuserpause=1`;
    }

    useEffect(() => {
        const handleFullscreenChange = () => {
            setIsFullscreen(document.fullscreenElement != null);
        };

        document.addEventListener('fullscreenchange', handleFullscreenChange);

        return () => {
            document.removeEventListener('fullscreenchange', handleFullscreenChange);
        };
    }, []);

    const handleClick = () => {
        const element = document.getElementById('zoom-iframe-total');
        if (element.requestFullscreen) {
            element.requestFullscreen();
        } else if (element.webkitRequestFullscreen) { /* Safari */
            element.webkitRequestFullscreen();
        } else if (element.msRequestFullscreen) { /* IE11 */
            element.msRequestFullscreen();
        }
    };

    const onLoad = async (iframe) => {
        const iframeWindow = iframe.contentWindow;

        // Inject JavaScript to hide the element and disable right-click
        document.addEventListener('contextmenu', (e) => {
            e.preventDefault();
        });
    };

    useEffect(() => {
        (async () => {
            const response = await securedFetchTimetableSlotById(slotId, navigateCallbackOptions(navigate));
            if (response === null) {
                return;
            }
            const slotStatus = await securedFetchSlotStatus(slotId, navigateCallbackOptions(navigate));
            if (slotStatus === null) {
                return;
            }
            if (slotStatus.status === 404) {
                alert("This slot's status was not found!");
                navigate(-1);
                return;
            }
            setLectureData({ ...response, status: slotStatus.status });
        })();
    }, [slotId, navigate]);



    return (
        <div id='zoom-iframe-total'>

            {lectureData ? (
                <div className="zoom-iframe-container flex">
                    <iframe
                        id="live-stream-iframe"
                        className="zoom-iframe"
                        src={getSrcUrl()}
                        onLoad={async (event) => await onLoad(event.target)}
                        allowFullScreen
                    />
                    {!isFullscreen && (
                        <button className="btn flex" id="full-screen" onClick={handleClick}>
                            Full Screen<MdOutlineFullscreen className="icon" />
                        </button>
                    )}
                    <div className="zoom-doubts flex">
                        {user === STUDENT_USER_TYPE ? <SlotLiveDoubts lectureData={lectureData} user={user} /> : <SlotLiveDoubtClusters lectureData={lectureData} user={user} />}
                    </div>
                </div>
            ) : (
                <Spinner />
            )}

        </div>
    );
};

export default LiveStreamIframeView;
