import { navigateCallbackOptions } from "./AuthenticationService";
import { getCurrentTimestamp } from "./DateService";
import { securedRenewHmacUrl, sha256, sha256String } from "./EncryptionService";
import { cloneDeep } from 'lodash';

export const createParams = (obj) => {
    if (obj === null) {
        return "";
    }
    let str = [];

    for (let key in obj) {
        if (obj.hasOwnProperty(key)) {
            if (Array.isArray(obj[key])) {
                for (let item of obj[key]) {
                    str.push(encodeURIComponent(key) + "=" + encodeURIComponent(item));
                }
            } else {
                str.push(encodeURIComponent(key) + "=" + encodeURIComponent(obj[key]));
            }
        }
    }
    if (str.length > 0) {
        return "?" + str.join("&");
    }
    return "";
}

export const updateArray = (array, i, newValue) => {
    return [...array.slice(0, i), newValue, ...array.slice(i + 1)];
}

export const containsEmptyArray = (obj) => {
    for (let key in obj) {
        if (Array.isArray(obj[key])) {
            if (obj[key].length === 0) {
                return true;
            }
        }
    }
    return false;
}

const needToUpdateSearchParams = (oldSearchParams, newParams) => {
    for (let key in newParams) {
        if (oldSearchParams.get(key) !== newParams[key]) {
            return true;
        }
    }
    return false;
}

export const updateSearchParamsInternal = (setter, oldSearchParams, newParams, replace = false) => {

    const combinedParams = {};
    oldSearchParams.forEach((value, key) => {
        combinedParams[key] = value;
    });
    for (let key in newParams) {
        if (newParams.hasOwnProperty(key)) {
            combinedParams[key] = newParams[key];
        }
    }
    setter(combinedParams, { replace: replace });
}

export const updateSearchParams = (setter, oldSearchParams, newParams, replace = false) => {
    if (!needToUpdateSearchParams(oldSearchParams, newParams)) {
        return;
    }
    updateSearchParamsInternal(setter, oldSearchParams, newParams, replace);
}

export const emptySearchParams = (setter, replace = false) => {
    updateSearchParamsInternal(setter, [], {}, replace);
}

export const checkArraysEqual = (array, toCompare) => {
    if (array.length !== toCompare.length) {
        return false;
    }
    array.sort();
    toCompare.sort();
    for (var i = 0; i < array.length; ++i) {
        if (array[i] !== toCompare[i]) return false;
    }
    return true;
}

export const getUniqueValues = (array) => {
    if (array.length === 0) {
        return [];
    }
    array.sort();
    const result = [array[0]];
    for (var i = 1; i < array.length; i++) {
        if (array[i] !== array[i - 1]) {
            result.push(array[i]);
        }
    }
    return result;
}

export const truncateString = (input, length = 20) => {
    if (input.length <= 10) {
        return input;
    }
    const parts = input.split(" ");
    let lengthLeft = length;
    let partsLeft = parts.length;
    return parts.map(part => {
        let newPart;
        const expectedPartLength = Math.max(3, Math.floor(lengthLeft / partsLeft));
        if (part.length <= expectedPartLength) {
            newPart = part;
        }
        else {
            newPart = `${part.slice(0, expectedPartLength)}.`;
        }
        lengthLeft = lengthLeft - newPart.length;
        partsLeft = partsLeft - 1;
        return newPart;
    }).join(' ');
}

export const cacheImages = async (imageArray) => {
    const promises = await imageArray.map(src => {
        return new Promise((resolve, reject) => {
            const img = new Image();
            img.src = src;
            img.onload = resolve();
            img.onerror = reject();
        });
    });

    await Promise.all(promises);
}

export const getHmacUrl = async (url, navigate = null) => {
    if (url.includes('blob')) {
        return url;
    }
    const parsedUrl = new URL(url);
    const params = parsedUrl.searchParams;
    if (params.get('timestamp') && getCurrentTimestamp() - params.get('timestamp') < 300) {
        return url;
    }
    let hmacResponse = null;
    if (navigate) {
        hmacResponse = await securedRenewHmacUrl(url, navigateCallbackOptions(navigate));
    } else {
        hmacResponse = await securedRenewHmacUrl(url);
    }
    if (hmacResponse === null) {
        return url;
    }
    return hmacResponse.url;
}

export const replaceImagesInObject = async (objectList, navigate, keys = ['image', 'url']) => {
    // Helper function to traverse and replace the URL

    const replaceUrl = async (obj, keys) => {
        let current = obj;
        for (let i = 0; i < keys.length - 1; i++) {
            current = current[keys[i]];
            if (current === undefined) {
                return;
            }
        }
        const lastKey = keys[keys.length - 1];
        if (current && current[lastKey]) {
            current[lastKey] = await getHmacUrl(current[lastKey], navigate);
        }
    };

    const copiedObjectList = cloneDeep(objectList);
    for (let obj of copiedObjectList) {
        await replaceUrl(obj, keys);
    }

    return copiedObjectList;
};

export const urlToFile = async (url, filename, mimeType) => {
    let res;
    if (url.includes('http')) {
        res = await fetch(url, {
            mode: "cors",
            headers: {
                'Cache-Control': 'no-cache'
            }
        });
    } else {
        res = await fetch(url);
    }
    const buf = await res.arrayBuffer();
    return new File([buf], filename, { type: mimeType });
}
