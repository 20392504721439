import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import Modal from 'react-modal';

import moment from 'moment';

import './testModal.css'

import Aos from 'aos'
import 'aos/dist/aos.css'

import MultiBatchGradeSelection from '../../Batches/MultiBatchGradeSelection';
import SelectionDropdown from '../../Common/SelectionDropdown';
import { FULL_TEST_TYPES, TEST_CREATE_TYPE_BASIC, securedCreateAutomatedTest, securedCreateTest, securedCreateTestSeries, securedCreateTestUsers, securedDeleteTestUsers, securedFetchAllTestSeries, securedUpdateTest } from '../../../services/TestService';
import { UnauthorizedError } from '../../../services/Errors';
import { ADMIN_USER_TYPE, securedFetchUsers } from '../../../services/UserService';
import { navigateCallbackOptions } from '../../../services/AuthenticationService';

import { AiFillCloseCircle } from "react-icons/ai";
import { FiFilePlus } from "react-icons/fi";
import SelectionDropdownMultiple from '../../Common/SelectionDropdownMultiple';
import { checkArraysEqual, getUniqueValues } from '../../../services/CommonService';
import LoadingPage from '../../Common/LoadingPage';
import SyllabusFilter from '../../Syllabus/SyllabusFilter';
import AutoTestSectionData from './AutoTestSectionData';
import AutoTestCreate from '../AutoTestCreate';
import { securedFetchTagsByFilter } from '../../../services/TagService';

Modal.setAppElement('#root');

const TestUpsert = ({ isOpen, onRequestClose, onTestUpsert = null, testData = null, user = ADMIN_USER_TYPE }) => {
    const [testName, setTestName] = useState(testData && testData.test_name ? testData.test_name : '');
    const [allTestSeries, setAllTestSeries] = useState([]);
    const [testSeries, setTestSeries] = useState(testData && testData.test_series_id ? { id: testData.test_series_id } : {});
    const [testType, setTestType] = useState(testData && testData.test_type ? FULL_TEST_TYPES.find(entry => entry.id === testData.test_type) || FULL_TEST_TYPES[0] : FULL_TEST_TYPES[0]);
    const [grades, setGrades] = useState([]);
    const [selectedGrades, setSelectedGrades] = useState([]);
    const [batches, setBatches] = useState([]);
    const [selectedBatches, setSelectedBatches] = useState(testData && testData.batches ? testData.batches.map(batch => ({ id: batch.batch_id })) : []);
    const [students, setStudents] = useState([]);
    const [selectedStudents, setSelectedStudents] = useState(testData && testData.users ? testData.users.map(user => ({ id: user.user_id })) : []);
    const [testDurationMinutes, setTestDurationMinutes] = useState(testData && testData.test_duration_minutes ? testData.test_duration_minutes : 0);
    const [testDateTime, setTestDateTime] = useState(testData && testData.test_start_time ? testData.test_start_time : '')
    const [isAutomatedTest, setAutomatedTest] = useState(false);

    const [subjects, setSubjects] = useState([]);
    const [selectedSubjects, setSelectedSubjects] = useState([]);
    const [topicGrades, setTopicGrades] = useState([]);
    const [selectedTopicGrades, setSelectedTopicGrades] = useState([]);
    const [mainTopics, setMainTopics] = useState([]);
    const [selectedMainTopics, setSelectedMainTopics] = useState([]);
    const [topics, setTopics] = useState([]);
    const [selectedTopics, setSelectedTopics] = useState([]);
    const [tags, setTags] = useState([]);
    const [isEntireSyllabus, setEntireSyllabus] = useState(true);
    const [testDifficulty, setTestDifficulty] = useState(0.5);
    const [selectedTags, setSelectedTags] = useState([]);

    const [subjectsData, setSubjectsData] = useState([]);
    const [sectionsData, setSectionsData] = useState([]);

    const [isSaving, setIsSaving] = useState(false);
    const navigate = useNavigate();

    const fetchAndSetAllTestSeries = async () => {
        const allTestSeriesResponse = await securedFetchAllTestSeries(navigateCallbackOptions(navigate));
        if (allTestSeriesResponse === null) {
            return null;
        }
        return allTestSeriesResponse.data;
    };

    useEffect(() => {
        (async () => {
            if (!isOpen) {
                return;
            }
            const testSeriesList = await fetchAndSetAllTestSeries();
            if (testSeriesList === null) {
                return;
            }
            setAllTestSeries(testSeriesList);
            setTestSeries(testSeriesList.find(series => series.id === testSeries.id) || {});
            const allStudents = await securedFetchUsers('students', {}, navigateCallbackOptions(navigate));
            if (allStudents === null) {
                return;
            }
            const studentEntries = allStudents.data.map(student => ({ name: `${student.user_data.first_name} ${student.user_data.last_name}`, id: student.id }));
            setStudents(studentEntries);
            const newSelectedStudents = testData && testData.users ? testData.users.map(user => ({ id: user.user_id })) : [];
            const selectedStudentIds = newSelectedStudents.map(student => student.id);
            setSelectedStudents(studentEntries.filter(student => selectedStudentIds.includes(student.id)));
            const tagsResponse = await securedFetchTagsByFilter({}, navigateCallbackOptions(navigate));
            if (tagsResponse === null) {
                return;
            }
            setTags(tagsResponse.data);
        })();
    }, [isOpen]);


    useEffect(() => {
        if (testData === null) {
            return;
        }
        setTestName(testData.test_name);
        setTestSeries(testData.test_series_id ? { id: testData.test_series_id } : {});
        setTestType(testData.test_type ? FULL_TEST_TYPES.find(entry => entry.id === testData.test_type) || FULL_TEST_TYPES[0] : FULL_TEST_TYPES[0]);
        setSelectedBatches(testData.batches ? testData.batches.map(batch => ({ id: batch.batch_id })) : []);
        setSelectedStudents(testData.users ? testData.users.map(user => ({ id: user.user_id })) : []);
        setTestDurationMinutes(testData.test_duration_minutes ? testData.test_duration_minutes : 0);
        setTestDateTime(testData.test_start_time ? testData.test_start_time : '');
    }, [testData]);

    const onTestSeriesSelectionChange = (selection) => {
        const testSeriesId = selection.value ? selection.value : null;
        if (testSeriesId) {
            setTestSeries({ id: selection.value, test_series_name: selection.label })
        }
    }

    const makeTopicsList = () => {
        if (selectedSubjects.length === 0 && selectedTopicGrades.length === 0 && selectedMainTopics.length === 0 && selectedTopics.length === 0) {
            return null;
        }
        let allSelectedTopics = [];
        if (!topics || !mainTopics) {
            return [];
        }
        if (selectedTopics.length > 0) {
            allSelectedTopics = allSelectedTopics.concat(selectedTopics);
        } else if (selectedMainTopics.length > 0) {
            allSelectedTopics = allSelectedTopics.concat(selectedMainTopics.concat(topics));
        }
        return allSelectedTopics.length === 0 ? topics.concat(mainTopics) : allSelectedTopics;
    }

    const onTestSeriesAdd = async (addition) => {
        const data = {
            'test_series_name': addition.test_series_name
        };
        const response = await securedCreateTestSeries(data, navigateCallbackOptions(navigate));
        if (response === null) {
            return;
        }
        const newItem = { id: response.test_series_id, test_series_name: addition.test_series_name };
        setAllTestSeries([...allTestSeries, newItem]);
        setTestSeries(newItem);

    }

    const onSelectedBatchToggle = (selected) => {
        const selectedIds = selected.map(sel => sel.value);
        const selectedBatchesFromSelect = batches.filter(batch => selectedIds.includes(batch.id));
        setSelectedBatches(selectedBatchesFromSelect);
    };

    const onTestDurationChange = (event) => {
        if (event.target.value < 0) {
            setTestDurationMinutes(0);
        } else {
            setTestDurationMinutes(parseInt(event.target.value));
        }
    }

    const onTestDateTimeChange = (event) => {
        setTestDateTime(event.target.value);
    }

    const onSelectedStudentToggle = async (selected) => {
        const selectedIds = selected.map(sel => sel.value);
        const newStudents = students.filter(student => selectedIds.includes(student.id));
        setSelectedStudents(newStudents);
    };

    const onSelectedTagChange = (selection) => {
        // const { value, checked } = event.target;
        const selectedIds = selection.map(sel => sel.value);
        const newSelectedTags = tags.filter(tag => selectedIds.includes(tag.id));
        setSelectedTags(newSelectedTags);
    };

    const updateUsersData = async (testId) => {
        const oldUserIds = testData && testData.users ? testData.users.map(user => user.user_id) : [];
        const newUserIds = selectedStudents.map(student => student.id);
        const newUsers = newUserIds.map(id => ({ user_id: id }));
        if (checkArraysEqual(oldUserIds, newUserIds)) {
            return true;
        }
        if (oldUserIds.length > 0) {
            const deleteResponse = await securedDeleteTestUsers(testId, { user_ids: oldUserIds }, navigateCallbackOptions(navigate));
            if (deleteResponse === null) {
                return false;
            }
        }
        if (newUserIds.length > 0) {
            const createResponse = await securedCreateTestUsers(testId, { test_users: newUsers }, navigateCallbackOptions(navigate));
            if (createResponse === null) {
                return false;
            }
        }
        return true;
    }

    const makeTestSectionsData = () => {
        const mappedSectionsData = sectionsData.map((data, index) => ({
            subject_id: parseInt(data.subject_id),
            number_of_questions: parseInt(data.number_of_questions),
            question_type: data.type.id === 'paragraph' ? 'paragraph' : 'standalone',
            question_subtype: data.type.id === 'paragraph' ? data.sub_type.id : data.type.id,
            test_section: {
                full_marks: parseInt(data.full_marks),
                partial_marks: parseInt(data.partial_marks),
                negative_marks: parseInt(data.negative_marks),
            }
        }));
        const subjectWiseSections = {};
        mappedSectionsData.forEach(data => {
            const dataSubjectId = data.subject_id;
            if (subjectWiseSections[dataSubjectId] == null) {
                subjectWiseSections[dataSubjectId] = [];
            }
            delete data['subject_id'];
            subjectWiseSections[dataSubjectId].push({ ...data, test_section: { section_order: subjectWiseSections[dataSubjectId].length + 1, ...data.test_section } });
        });
        return Object.keys(subjectWiseSections).map((subjectId, index) => ({
            test_subject: { subject_id: parseInt(subjectId), subject_order: index + 1 }, test_sections: subjectWiseSections[subjectId]
        }));
    }

    const checkIfDataValid = () => {
        if (testName.length === 0) {
            alert('Test Name is not mentioned!');
            return false;
        }
        if (selectedBatches.length === 0 && isAutomatedTest && (selectedSubjects.length === 0 && selectedTopicGrades.length === 0 && selectedMainTopics.length === 0 && selectedTopics.length === 0)) {
            alert("Atleast one of either batch or topics must be selected. Cannot auto generate test for no batch without any topic assigned!")
            return false;
        }
        if (testDurationMinutes === 0 || testDurationMinutes === null) {
            alert("Test duration must be greater than 0 minutes!");
            return false;
        }

        if (isAutomatedTest) {
            if (subjectsData.length === 0) {
                alert("There should be atleast one subject in auto generated test!");
                return false;
            }

            if (sectionsData.length === 0) {
                alert("There should be atleast one section in auto generated test!");
                return false;
            }
            for (let i = 0; i < sectionsData.length; i++) {
                const sectionData = sectionsData[i];
                if (parseInt(sectionData.number_of_questions) === 0) {
                    alert("Section with 0 questions exists!");
                    return false;
                }
                if (parseInt(sectionData.full_marks) === 0) {
                    alert("Section exists with 0 full marks per question!");
                    return false;
                }
            }
        }
        return true;
    }


    const createBasicTestData = () => {
        const data = {
            test_name: testName,
            test_batches: selectedBatches.map((item) => ({ batch_id: item.id })),
            test_type: testType.id,
            test_duration_minutes: testDurationMinutes
        }
        if (testSeries && testSeries.id) {
            data['test_series_id'] = testSeries.id;
        }
        if (testDateTime.length > 0) {
            data['test_start_time'] = testDateTime;
        }
        return data;
    }

    const createAutomatedTestData = (basicTestData) => {
        const data = { test: { ...basicTestData, difficulty: parseFloat(testDifficulty) } };
        const topicsToTakeFrom = makeTopicsList();
        const tagsToTakeFrom = selectedTags.length > 0 ? selectedTags : null;
        if (topicsToTakeFrom !== null) {
            data['topic_ids'] = topicsToTakeFrom.map(topic => topic.id);
        }
        if (tagsToTakeFrom !== null) {
            data['tag_ids'] = tagsToTakeFrom.map(tag => tag.id);
        }
        data['test_subjects'] = makeTestSectionsData();
        return data;

    }

    const handleSubmit = async () => {
        setIsSaving(true);
        const isDataValid = checkIfDataValid();
        if (!isDataValid) {
            setIsSaving(false);
            return;
        }
        let response = null;
        const basicTestData = createBasicTestData();
        if (!testData) {
            if (!isAutomatedTest) {
                response = await securedCreateTest(TEST_CREATE_TYPE_BASIC, basicTestData, navigateCallbackOptions(navigate));
            } else {
                response = await securedCreateAutomatedTest(TEST_CREATE_TYPE_BASIC, createAutomatedTestData(basicTestData), navigateCallbackOptions(navigate));
            }
        } else {
            response = await securedUpdateTest(testData.id, basicTestData, navigateCallbackOptions(navigate));
        }
        if (response === null) {
            setIsSaving(false);
            return;
        }
        const upsertTestId = testData && testData.id ? testData.id : response.test_id;
        const updateUsersSuccess = await updateUsersData(upsertTestId);
        if (!updateUsersSuccess) {
            setIsSaving(false);
            return;
        }
        setIsSaving(false);
        onRequestClose();
        if (onTestUpsert) {
            await onTestUpsert(testData ? testData.id : response.test_id);
        }
    }

    const onSubmit = (event) => {
        event.preventDefault();
    };

    useEffect(() => {
        Aos.init({ duration: 1000 })
    }, [])

    const renderTestCreate = () => {
        if (isSaving) {
            return <LoadingPage type='save' />;
        }
        return (
            <>
                <h3 className="modalTitle"> {testData ? 'Edit' : 'Create New'} Test </h3>

                <form className="testContent flex" onSubmit={onSubmit} >

                    <div className="testInfoInput">
                        <label className="title">Name</label>
                        <input type="text" className="text" value={testName} onChange={e => setTestName(e.target.value)} placeholder="Enter..." required />
                    </div>

                    <SelectionDropdown className="testSelectionDropdown" name="Series" itemList={allTestSeries} selectedItem={testSeries} onAddItem={onTestSeriesAdd} onSelectionChange={onTestSeriesSelectionChange} valueField="id" nameField="test_series_name" placeHolderText="Select or Enter..." required={true} isSearchable={true} />

                    <MultiBatchGradeSelection
                        grades={grades} setGrades={setGrades}
                        selectedGrades={selectedGrades} setSelectedGrades={setSelectedGrades}
                        batches={batches} setBatches={setBatches}
                        selectedBatches={selectedBatches} setSelectedBatches={setSelectedBatches}
                        onSelectedBatchesToggle={onSelectedBatchToggle}
                        prefillGrades={true}
                    />

                    <SelectionDropdownMultiple className="testSelectionDropdownMultiple" name="Students" itemList={students} selectedItems={selectedStudents} onSelectionToggle={onSelectedStudentToggle} valueField="id" nameField="name" required={true} isSearchable={true} />


                    <div className="testInfoInput flex">
                        <label className="title">Duration (minutes)</label>
                        <input className="number" type="number" value={testDurationMinutes} onChange={onTestDurationChange} placeholder="Enter..." required />
                    </div>

                    <div className="testInfoInput flex">
                        <label className="title">Test Time</label>
                        <input className="date" type="datetime-local" value={testDateTime} onChange={onTestDateTimeChange} lang="en-GB" />
                    </div>
                    {testData === null && (
                        <div className="automatedTestCheck flex">

                            <div className="automatedTestCheckHeader">
                                <div className="checkBox flex">
                                    <label className="title">Choose Questions Automatically</label>
                                    <input
                                        type="checkbox"
                                        checked={isAutomatedTest}
                                        onChange={() => setAutomatedTest(prev => !prev)}
                                    />
                                </div>
                            </div>

                            {isAutomatedTest && (
                                <>
                                    <div className="syllabusCheck flex">
                                        <label className="title">Cover Entire Syllabus</label>
                                        <input
                                            type="checkbox"
                                            checked={isEntireSyllabus}
                                            onChange={() => setEntireSyllabus(prev => !prev)}
                                        />
                                    </div>

                                    {!isEntireSyllabus && (
                                        <>
                                            <div className="syllabusFilter flex">
                                                <SyllabusFilter subjects={subjects} setSubjects={setSubjects} selectedSubjects={selectedSubjects} setSelectedSubjects={setSelectedSubjects}
                                                    grades={topicGrades} setGrades={setTopicGrades} selectedGrades={selectedTopicGrades} setSelectedGrades={setSelectedTopicGrades}
                                                    mainTopics={mainTopics} setMainTopics={setMainTopics} selectedMainTopics={selectedMainTopics} setSelectedMainTopics={setSelectedMainTopics}
                                                    topics={topics} setTopics={setTopics} selectedTopics={selectedTopics} setSelectedTopics={setSelectedTopics} user={user} />


                                            </div>
                                            <div className="filter">
                                                <SelectionDropdownMultiple className="testSelectionDropdownMultiple" name="Tags" itemList={tags} selectedItems={selectedTags} onSelectionToggle={onSelectedTagChange} nameField={'tag_name'} valueField={'id'} isSearchable={true} />
                                            </div>
                                        </>
                                    )}
                                    {/* <div className="syllabus-filter"> */}

                                    {/* </div> */}
                                    <div className="testInfoInput flex">
                                        <label className="title">Difficulty</label>
                                        <span className="sliderValue">{testDifficulty}</span>
                                        <span className="sliderRange">Easy(0)</span>
                                        <input className="number" type="range" id="slider" min={0} max={1} step="0.01" value={testDifficulty} onChange={(event) => setTestDifficulty(event.target.value)} required />
                                        <span className="sliderRange">Hard(1)</span>
                                        {/* <input className="number" type="number" min={0} max={1} value={testDifficulty} onChange={(event) => setTestDifficulty(event.target.value)} placeholder="Enter..." required /> */}
                                    </div>
                                    <AutoTestCreate subjectsData={subjectsData} setSubjectsData={setSubjectsData} sectionsData={sectionsData} setSectionsData={setSectionsData} isEntireSyllabus={isEntireSyllabus} subjects={subjects} setSubjects={setSubjects} selectedSubjects={selectedSubjects} selectedMainTopics={selectedMainTopics} selectedTopics={selectedTopics} />
                                </>
                            )}
                        </div>)}
                </form>

                <button className='btn flex' id='submit' onClick={handleSubmit}>
                    {testData ? 'Save' : 'Create'} <FiFilePlus className="icon" />
                </button>

                <AiFillCloseCircle className="icon" id='close' onClick={onRequestClose} />
            </>
        )
    }

    return (
        <Modal overlayClassName="customModal" className="testModal flex" isOpen={isOpen} onRequestClose={onRequestClose}>

            {renderTestCreate()}

        </Modal >
    );
}

export default TestUpsert;
